<script setup lang="ts">
interface Props {
  show?: boolean
  visible?: boolean
  plain?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  show: false,
  visible: true,
  plain: false
})

const emit = defineEmits(['closed'])

const close = () => emit('closed')
</script>

<template>
  <div v-if="props.show" v-show="props.visible" class="modal-view">
    <div class="modal-content">
      <button class="close icon" @click="emit('closed')">
        <Icon name="close" />
      </button>
      <slot :close="close" />
    </div>
  </div>
</template>
